import React, { useEffect, useRef, useState } from 'react';
import './../App.css';
import Carousel from '../Components/Carousel';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Home() {
    const homeref = useRef(null);
    const aboutRef = useRef(null);
    const nftsRef = useRef(null);
    const tokenomicsRef = useRef(null);
    const roadmapRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    const [timeRemaining, setTimeRemaining] = useState('');
    const [isCountdownOver, setIsCountdownOver] = useState(false);

    useEffect(() => {
        const targetTime = new Date();
        targetTime.setDate(targetTime.getDate() + 0); // Set target for next day
        targetTime.setHours(7, 0, 0, 0); // Set target time to 6:00 PM next day

        const updateTimer = () => {
            const currentTime = new Date();
            const timeDifference = targetTime - currentTime;
// Logging the time difference
console.log(`Time difference: ${timeDifference} ms`);
            if (timeDifference <= 0) {
                setIsCountdownOver(true);
                clearInterval(timerInterval);
            } else {
                const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDifference / 1000) % 60);
                setTimeRemaining(`${hours}h : ${minutes}m : ${seconds}s`);
            }
        };

        const timerInterval = setInterval(updateTimer, 1000);

        updateTimer();

        return () => clearInterval(timerInterval);
    }, []);

    return (
        <>
            <Helmet>
                <title>ROKETCOIN | Home</title>
            </Helmet>
            <nav ref={homeref} style={{ position: "relative", zIndex: "1" }}>
                <div className='nav-title'>
                    <img src='/Asserts/logo.png' alt='logo'></img>
                    <h1>ROKETCOIN</h1>
                </div>
                <div className='nav-items'>
                    <div className='nav-item mid'>
                        <p onClick={() => scrollToSection(aboutRef)}>ABOUT</p>
                        <p >  <a href='https://roketcoin-team.gitbook.io/roketcoin-en'>WHITEPAPER</a></p>
                        <p onClick={() => scrollToSection(tokenomicsRef)}>TOKENOMICS</p>
                        <p onClick={() => scrollToSection(roadmapRef)}>ROADMAP</p>

                    </div>
                    <div className='nav-item left'>
                    <a href="https://www.tiktok.com/@roketcoin_news" target="_blank"><i className="fa-brands fa-tiktok"></i></a>
                    <a href="https://x.com/roketcoin_news" target="_blank"><i className="fa-brands fa-twitter"></i></a>
                        <a href="https://t.me/RoketCoinOfficial" target="_blank"><i className="fa-brands fa-telegram"></i></a>
                    </div>
                </div>
            </nav>
            <div className='home-section' >
                <img className="home-abs" src='/Asserts/abstract.jpg' alt='abstract'></img>
                <div className='home-container'>
                    <div className='home-title'>
                        <h1>ROKETCOIN</h1>
                        <p style={{ position: "relative", zIndex: "1" }}>Welcome to our ROKETCOIN project, the largest utility token on the BASE network.</p>
                        <div className='timer-buy'>
                            {isCountdownOver ? <h5 className='live-now' style={{ zIndex: "2" }}>LIVE NOW</h5> : <h5>LIVE IN</h5>}
                            {isCountdownOver ? (
                                <a href='https://privatesale.roketcoin.com/' className='buy-now-btn' style={{ position: "relative", zIndex: "1" }}>Buy Now Presale <span></span></a>
                            ) : (
                                <p className='timer'>{timeRemaining}</p>
                            )}
                        </div>
                    </div>
                    <div className='home-img'>
                        <div className='outer-layer'></div>
                        <div className='mid-layer'></div>
                        <div className='inner-layer'></div>
                        <img src='/Asserts/bg.png' alt='background'></img>
                    </div>
                </div>
            </div>
            <div className='home-intro-features'>
                <p><i className="fa-solid fa-globe"></i> Trading Crypto Bot</p>
                <p><i className="fa-solid fa-globe"></i> Social Media </p>
                <p><i className="fa-solid fa-rotate"></i> Buy Sell & Swap</p>
                <p><i className="fa-solid fa-store"></i> Partnership with Local Business</p>
            </div>
            <div className='customers-container'>
                <h1>Our <span>Partners</span></h1>
                <div className="logos">
                    <div className="logos-slide">
                        <img src='/Asserts/Customers/partner1.avif' alt='customer 1'></img>
                        <img src='/Asserts/Customers/partner2.webp' alt='customer 2'></img>
                        <img src='/Asserts/Customers/partner3.avif' alt='customer 3'></img>
                        <img src='/Asserts/Customers/partner4.avif' alt='customer 4'></img>
                        <img src='/Asserts/Customers/partner5.avif' alt='customer 5'></img>
                        <img src='/Asserts/Customers/partner6.png' alt='customer 6'></img>
                    </div>
                    <div className="logos-slide">
                        <img src='/Asserts/Customers/partner1.avif' alt='customer 1'></img>
                        <img src='/Asserts/Customers/partner2.webp' alt='customer 2'></img>
                        <img src='/Asserts/Customers/partner3.avif' alt='customer 3'></img>
                        <img src='/Asserts/Customers/partner4.avif' alt='customer 4'></img>
                        <img src='/Asserts/Customers/partner5.avif' alt='customer 5'></img>
                        <img src='/Asserts/Customers/partner6.png' alt='customer 6'></img>
                    </div>
                </div>
            </div>
            <div className='about-section' ref={aboutRef}>
                <div className='about-container'>
                    <img src='/Asserts/bgabout.png' alt='about'></img>
                    <div className='about-content'>
                        <h1>About <span>ROKETCOIN</span></h1>
                        <p>
                        About ROKETCOIN: Our token has several features designed for long-term use, including a crypto trading bot feature, a social media chatting app where you can earn money, cryptocurrency buying and selling, and business partnerships with local brands.
                        </p>
                        <p>
                            With support Gen-z community globaly with confidence to get the moon price quickly
                        </p>
                        
                        <a href='/'>Buy Now</a>
                    </div>
                </div>
            </div>
            <div ref={nftsRef}>
              <br></br>
              <br></br>
              <br></br>
            </div>
            <div className='tokenomics-section' ref={tokenomicsRef}>
            <br></br><br></br><br></br><br></br><br></br>
                <h1>TOKEN<span>OMICS</span></h1>
                <div className='tokenomics-container'>
                    <div className='tokennomics-img'>
                        <img src='/Asserts/16.jpg' alt='tokenomics'></img>
                    </div>
                    <div className="tokenomics-content">
                        <h1>ROKETCOIN</h1>
                        <p>✅ Total Supply: 100,000,000</p>
                        <p>✅ Presale: 40%</p>
                        <p>✅ Liquidity Pool (LP): 22.8%</p>
                        <p>✅ Rewards/Giveaways for community: 10%</p>
                        <p>✅ Exchange Listing: 6%</p>
                        <p>✅ Airdrop: 4%</p>
                        <p>✅ Team & Development: 7.2%</p>
                        <p>✅ Marketing & Promotion: 7%</p>
                        <p>✅ Partnership Local Business: 3%</p>
                        <p>🤑 0% / 0% Tax</p>
                        <p>📑 CA: TBA</p>
                        <a href='/'></a>
                    </div>
                </div>
            </div>
            <div className='roadmap-section' ref={roadmapRef}>
                <h1>ROAD<span>MAP</span></h1>
                <div className='roadmap-container'>
                    <div className='roadmap-qs'>
                        <h1>Phase 1</h1>
                        <p>
                            Make Concept & Build Team ✅<br />
                            Research and building concept ROKETCOIN Token ✅<br />
                            Website Launch ✅<br />
                            Socials Launch ✅<br />
                            Make whitepaper & Roadmap, social media channel ✅<br />
                            Community Building ✅<br />
                            Marketing Building <br />
                            Token Creation <br />
                            TG & Twitter Shilling <br />
                            TG & Twitter Raids <br />
                        </p>
                    </div>
                    <div className='roadmap-qs'>
                        <h1>Phase 2</h1>
                        <p>
                            Release Airdrop in Social Media App (Chating get Token) <br />
                            Target goal 5000 holder <br />
                            Dexscreener Trending <br />
                            Dextools Trending <br />
                            Dexview Trending <br />
                            CG / CMC Fast Tracking<br />
                            Influencer Partnerships<br />
                            New Youtube Reviews<br />
                        </p>
                    </div>
                    <div className='roadmap-qs'>
                        <h1>Phase 3</h1>
                        <p>
                            IDO/Presale launch on website (www.roketcoin.com) & Pinksale<br />
                            Swap & Exchange platform launch (platform will be announce later) <br />
                            CoinMarketCap listing <br />
                            CoinGecko listing <br />
                            UniSwap listing <br />
                            
                        </p>
                    </div>
                    <div className='roadmap-qs'>
                        <h1>Phase 4</h1>
                        <p>
                            Make Mass social media promotion and colaboration with local business <br />
                            CEX Listing<br />
                            Any update roadmap will update again<br />
                        </p>
                    </div>
                </div>
            </div>
            <footer>
                <div className='footer-main-container'>
                    <div className='footer-sub-container'>
                        <h1>ROKET<span>COIN</span></h1>
                        <div className='nav-item left'>
                         <a href="https://www.tiktok.com/@roketcoin_news" target="_blank"><i className="fa-brands fa-tiktok"></i></a>
                            <a href="https://x.com/roketcoin_news" target="_blank"><i className="fa-brands fa-twitter"></i></a>
                            <a href="https://t.me/RoketCoinOfficial" target="_blank"><i className="fa-brands fa-telegram"></i></a>
                        </div>
                    </div>
                    <div className='footer-navs'>
                        <p onClick={() => scrollToSection(homeref)}>HOME</p>
                        <p onClick={() => scrollToSection(aboutRef)}>ABOUT</p>
                        <p onClick={() => scrollToSection(tokenomicsRef)}>TOKENOMICS</p>
                        <p onClick={() => scrollToSection(roadmapRef)}>ROADMAP</p>
                    </div>
                </div>
                <p className='footer-dis'>© 2024 BY <span>ROKETCOIN.</span> ALL RIGHTS RESERVED!</p>
            </footer>
        </>
    );
}
